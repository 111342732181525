.footer {
    background-color: #4A90E2; /* Cor de fundo azul claro */
    color: white; /* Texto em branco */
    text-align: center; /* Centraliza o texto */
    padding: 10px 0; /* Espaçamento superior e inferior */
    box-shadow: 0 -2px 10px rgb(255, 255, 255); /* Sombra sutil para profundidade */
    max-width: 1200px; /* Limita a largura máxima do footer */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 8px; /* Bordas levemente arredondadas */
}

.footer p {
    margin: 1px 0; /* Espaçamento vertical entre os parágrafos */
    font-size: 1rem; /* Tamanho do texto dos parágrafos */
    line-height: 1.4; /* Altura da linha para melhor leitura */
    color: white; /* Garantir que o texto dos parágrafos seja branco */
}

.footer p:last-child {
    font-size: 0.85rem; /* Tamanho menor para o texto de copyright */
    opacity: 0.7; /* Transparência leve */
}

/* Estilos para links no footer */
.footer a {
    color: #ffffff; /* Cor branca para links */
    text-decoration: none; /* Remove o sublinhado dos links */
    transition: color 0.3s ease; /* Transição suave na mudança de cor */
}

.footer a:hover {
    color: #0056b3; /* Azul mais escuro ao passar o mouse */
}

/* Responsividade */
@media (max-width: 768px) {
    .footer {
        padding: 10px 0; /* Menos padding em telas menores */
    }

    .footer p {
        font-size: 0.9rem; /* Texto um pouco menor */
    }
}

@media (max-width: 480px) {
    .footer p {
        font-size: 0.8rem; /* Texto ainda menor em telas muito pequenas */
    }
}
